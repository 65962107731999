
const enAlert = {
    "Login to": "Login to",
    "Bind Email": "Bind Email",
    "Email": "Email",
    "Update Email": "Update Email",
    "Address": "Address",
    "Enter Reminder": "Enter Reminder",
    "Add": "Add",
    "Modify": "Modify",
    "Delete": "Delete",
    "Select a trading pair": "Select a trading pair",
    "Notification records": "Notification records",
    "Tip price": "Tip price",
    "Price": "Price",
    "Set Price": "Set Price",
    "Percentage": "Percentage",
    "Minimum prompt interval": "Minimum prompt interval",
    "Prompter labeling": "Prompter labeling",
    "Number of reminders": "Number of reminders",
    "Fee Estimate": "Fee Estimate",
    "Refund Fee Estimate": "Refund Fee Estimate",
    "View billing rules": "View billing rules",
    "Billing rules": "Billing rules",
    "Billing rules description": "Billing rules description",
    "Confirm Reminder": "Confirm Reminder",
    "Whether to determine the deletion of the prompter": "Whether to determine the deletion of the prompter",
    "off-ramp warning": "",
    "read rules": "read rules",
    "You will receive the remaining fee security deposit of": "You will receive the remaining fee security deposit of",
    "and an excess security deposit of": "and an excess security deposit of",
    "Billing rules 1": "The ARES-Reminder collateral system, which consists of a \"fee collateral\" + an \"excess collateral\"",
    "Billing rules 2": "The fee security deposit is used to pay for notices and is currently deducted by 1ARES per notice.",
    "Billing rules 3": "The overcollateralization is used to maintain network security, and the overcollateralization is currently fixed at 100 ARES.",
    "Billing rules 4": "When the \"reminder\" is removed from the chain, the remaining fees, minus the notification fee, are automatically unpledged, and the remaining portion can be transferred in and out freely by the user.",
    "Billing rules 5": "When the \"reminder\" is removed from the chain, the full cost of the overcollateralization is automatically unpledged and the user is free to transfer it in and out.",
}

export default enAlert;