

const zhRules = {
    "read rules": "我已阅读规则，同意参与。",
    "rule1": "每次价格竞猜需要花费测试币 100 ARES。如果您想要获得更多奖励，则支持 X2、X5玩法，需要花费测试币 200、500 ARES。",
    "rule2": "每期价格竞猜距离结束时间7天，会被上架到参与区。大于7天会留在预告区，过了结束时间则会进入往期查询。可以点击“我参与的预测”，快速查询自己的战况！",
    "rule3": "为了保证竞猜的公平有效，每期价格竞猜会在结束时间前1个小时，关闭预测窗口。请及时参与！每次价格竞猜，每人仅限参与一次！",
    "rule4": "每期价格竞猜会根据难易程度，偏差值等，给到不同的奖励。此奖励为BSC网络上的ARES代币，目前在市场上已有实际的通证价值。参与时，请留下您的BSC地址，等待结果领取奖励！",
    "rule5": "每期价格竞猜如没有获奖者，奖金将会累计至下一期。",
    "rule6": "每期价格竞猜如有多位获奖者，则一同瓜分奖励。如果存在 X2、X5玩法玩家，则按照比例获得奖励。（如：BSC上的500 ARES由3个普通预测者和1个 X2玩家、1个X5玩家获得。则一位普通预测者获得 1/3+2+5 奖励、X2玩家获得 2/3+2+5 奖励、X5玩家获得2/3+2+5 奖励）。值得说明的是，如果只存在一人获奖，且为X2、X5玩法玩家，则奖励与普通预测者相同，都为本期的全部奖金。",
    "rule7": "可以在“我参与的预测”或“往期”中，查询预测情况。如有疑问，欢迎咨询线上客服，留下有问题的轮次，出现的问题及正确的答案。",
}

export default zhRules;