
const zhAlert = {
    "Login to": "登录",
    "Bind Email": "绑定邮箱",
    "Email": "邮箱",
    "Update Email": "更新邮箱",
    "Address": "地址",
    "Enter Reminder": "进入价格监测",
    "Add": "新增",
    "Modify": "修改",
    "Delete": "删除",
    "Select a trading pair": "选择交易对",
    "Notification records": "通知记录",
    "Tip price": "通知价格",
    "Price": "价格",
    "Set Price": "设置价格",
    "Percentage": "比例",
    "Minimum prompt interval": "提示间隔",
    "Prompter labeling": "提示 Tag",
    "Number of reminders": "提醒次数",
    "Fee Estimate": "预估费用",
    "Refund Fee Estimate": "预估退还费用",
    "View billing rules": "查看计费规则",
    "Billing rules description": "计费规则",
    "Billing rules": "计费规则",
    "Confirm Reminder": "确认删除",
    "Whether to determine the deletion of the prompter": "是否确认删除",
    "off-ramp warning": "",
    "You will receive the remaining fee security deposit of": "您将收到剩余的费用保证金",
    "and an excess security deposit of": "和超额保证金",
    "read rules": "同意规则",
    "Billing rules 1": "ARES-Reminder抵押金额，由 \"收费抵押品 \"+\"超额抵押品 \"组成",
    "Billing rules 2": "费用保证金用于支付通知，目前是按每份通知扣减1ARES",
    "Billing rules 3": "超额抵押金用于维护网络安全，目前超额抵押金固定为100ARES",
    "Billing rules 4": "当 \"reminder \"从链上移除时，剩余的费用减去通知费后，自动解押，剩余部分可由用户自由转入和转出",
    "Billing rules 5": "当 \"reminder \"从链上移除时，超额抵押的全部费用就会自动解除抵押，用户可以自由转入和转出",
}

export default zhAlert;