

const enCommon = {
    "Home": "Home",
    "Ongoing": "Ongoing",
    "Completed": "Completed",
    "Upcoming": "Upcoming",
    "Price Prediction": "Price Prediction",
    "Price Fluctuations": "Price Fluctuations",
    "Test Coins": "Test Coins",
    "My Predictions": "My Predictions",
    "My Participated Predictions": "My Participated Predictions",
    "Rules": "Rules",
    "EN": "EN",
    "CN": "CN",
    "Submit": "Submit",
    "Cancel": "Cancel",
    "join": "join",
    "free": "free",
    "Fee": "Fee",
    "Address": "Address",
    "Hours Left": "Hours Left",
    "End Date": "End Date",
    "OK": "OK",
    "Consult": "Consult",
    "Result": "Result",
    "Total Rewards": "Total Rewards",
    "Coming soon": "Coming soon!",
    "Receive test coins": "Get test coins",
    "per day": "per day",
    "Number": "Number",
    "Live Time": "Live Time",
    "Stash Address": "Stash Address",
    "Your test coins": "Your test coins",
    "persons participated": "persons participated",
    "No one won in this prediction": "No one won in this prediction",
    "Price Prediction Rules": "Price Prediction Rules",
    "Final Price": "Final Price",
    "Winner List": "Winner List",
    "Joined": "Joined",
    "Account": "Account",
    "Price": "Price",
    "Median": "Median",
    "Average": "Average",
    "prediction": "Prediction",
    "winnerName": "Name",
    "winner": "winner",
    "Faucet": "Faucet",
    "The deviation rate is": "The deviation rate is",
    "Congratulations tips": "Congratulations on getting the reward for this prediction, the reward is 500 ARES!",
    "Come again tomorrow": "Your test coins have been claimed today. Come again tomorrow!",
    "you can get it through the tap below": "To participate in price estimate, you must have enough test coins first. If your balance is insufficient, you can get it through the tap below!",
    "successfully participated tips": "You have successfully participated in the prediction below, good luck!",
    "No one won in this prediction tips": "No one won in this prediction, and the prize will be accumulated to the next prediction about BTC!",
    "Sorry, your test coins are insufficient to participate!": "Sorry, your test coins are insufficient to participate!",
    "Receive test coins tips": "Each person can receive 50 ARES per day. Since the test coins only can be used for operation on Ares gladios, the test coin has no actual token value.",
    "You have already participated in this prediction, you cannot participate repeatedly!": "You have already participated in this prediction, you cannot participate repeatedly!",
    "site desc": "Ares is an on-chain-verified oracle protocol that provides secure and reliable data services for the Polkadot DeFi ecosystem.",
    "closed one hour before tips": "In order to ensure the fairness and effectiveness of the estimate, the prediction  window will be closed one hour before the end of each period of the price estimate. Please participate in time!",
    "Ares Protocol's mainnet alert tool keeps you informed of cryptocurrency price increases and decreases in real time.": "Ares Protocol's mainnet alert tool keeps you informed of cryptocurrency price increases and decreases in real time.",
    "To ensure fairness, the deadline is 1 hour in advance": "To ensure fairness, the deadline is 1 hour in advance",
}


export default enCommon;
