

export const Tags: any = {
    "BTC-USDT": "magenta",
    "ETH-USDT": "red",
    "BNB-USDT": "volcano",
    "XRP-USDT": "orange",
    "ADA-USDT": "gold",
    "DOGE-USDT": "lime",
    "MATIC-USDT": "green",
    "SOL-USDT": "cyan",
    "DOT-USDT": "blue",
    "LTC-USDT": "geekblue",
    "AVAX-USDT": "purple",
    "TRX-USDT": "magenta",
    "UNI-USDT": "red",
    "ATOM-USDT": "volcano",
    "XMR-USDT": "orange",
    "ETC-USDT": "gold",
    "BCH-USDT": "lime",
    "LINK-USDT": "green",
    "XLM-USDT": "cyan",
    "FIL-USDT": "blue",
    "1INCH-USDT": "geekblue",
    "AAVE-USDT": "purple",
    "ALGO-USDT": "magenta",
    "AXS-USDT": "red",
    "BAT-USDT": "volcano",
    "BNT-USDT": "orange",
    "BTT-USDT": "gold",
    "CELO-USDT": "lime",
    "CHZ-USDT": "green",
    "COMP-USDT": "cyan",
    "CRV-USDT": "blue",
    "DASH-USDT": "geekblue",
    "DCR-USDT": "purple",
    "EGLD-USDT": "magenta",
    "ENJ-USDT": "red",
    "EOS-USDT": "volcano",
    "FET-USDT": "orange",
    "FTM-USDT": "gold",
    "FTT-USDT": "lime",
    "GRT-USDT": "green",
    "HBAR-USDT": "cyan",
    "ICP-USDT": "blue",
    "ICX-USDT": "geekblue",
    "IOST-USDT": "purple",
    "IOTA-USDT": "magenta",
    "IOTX-USDT": "red",
    "KAVA-USDT": "volcano",
    "KNC-USDT": "orange",
    "KSM-USDT": "gold",
    "LRC-USDT": "lime",
    "LUNA-USDT": "green",
    "MANA-USDT": "cyan",
    "MKR-USDT": "geekblue",
    "NANO-USDT": "purple",
    "NEAR-USDT": "magenta",
    "NEO-USDT": "red",
    "OMG-USDT": "volcano",
    "ONT-USDT": "orange",
    "QTUM-USDT": "gold",
    "REN-USDT": "lime",
    "SAND-USDT": "green",
    "SC-USDT": "cyan",
    "SNX-USDT": "blue",
    "SRM-USDT": "geekblue",
    "STX-USDT": "purple",
    "SUSHI-USDT": "magenta",
    "THETA-USDT": "red",
    "UMA-USDT": "volcano",
    "VET-USDT": "orange",
    "WAVES-USDT": "gold",
    "XEM-USDT": "lime",
    "XTZ-USDT": "green",
    "YFI-USDT": "cyan",
    "ZEC-USDT": "blue",
    "ZEN-USDT": "geekblue",
    "ZIL-USDT": "purple",
    "ZRX-USDT": "geekblue",
}