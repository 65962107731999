



const enPopular = {
    "Popular": "Popular",
    "Having Countdown": "Having Countdown",
    "Reduction Countdown": "Reduction Countdown",
    "days": "days",
    "hours": "hours",
    "minutes": "minutes",
    "Estimated time to reduce": "Estimated time to reduce",
    "Current block reward": "Current block reward",
    "Block reward after reduction": "Block reward after reduction",
    "Remaining blocks until reduction": "Remaining blocks until reduction",
    "Block generation time": "Block generation time",
    "Blocks left to mine": "Blocks left to mine",
    "pre Share in": "Share in",
    "suf Share in": "",
    "Scan QR": "Scan QR",
    "Join the community": "Join the community",
    "Share": "Share",
    "Cancel": "Cancel",
}

export default enPopular;