
const zhCommon = {
    "Home": "主页",
    "Ongoing": "进行中",
    "Completed": "已完成",
    "Upcoming": "预告区",
    "Price Prediction": "价格预测",
    "Price Alert": "价格监测",
    "Price Fluctuations": "价格涨跌",
    "Test Coins": "测试币金额",
    "My Predictions": "我参与的预测",
    "My Participated Predictions": "我参与的预测",
    "Rules": "规则",
    "EN": "英语",
    "CN": "中文",
    "Submit": "同意",
    "Cancel": "取消",
    "join": "加入",
    "free": "花费",
    "Fee": "花费",
    "Address": "地址",
    "Hours Left": "还剩",
    "End Date": "结束时间排序",
    "OK": "好",
    "Consult": "咨询",
    "Result": "结果",
    "Total Rewards": "总奖励",
    "Coming soon": "即将到来！",
    "Number": "数量",
    "Live Time": "实时",
    "Stash Address": "Stash 地址",
    "Your test coins": "您的测试币",
    "persons participated": "人预测",
    "No one won in this prediction": "无人获得本次奖励",
    "No one won in this prediction tips": "本次竞猜无人获奖， 奖金将累计至下一期BTC价格竞猜！",
    "Come again tomorrow": "您今日的测试币已领取。明天再来吧！",
    "successfully participated ": "您已成功参与下面预测， 祝您好运！",
    "Price Prediction Rules": "价格预测规则",
    "Final Price": "最终价格",
    "Winner List": "获奖者名单",
    "Joined": "已参与",
    "Account": "账号",
    "Price": "价格",
    "Median": "中位数",
    "Average": "平均数",
    "The deviation rate is": "偏差范围在",
    "Receive test coins": "领取测试币",
    "per day": "每天",
    "prediction": "竞猜价格",
    "winnerName": "账号",
    "winner": "获胜者",
    "Faucet": "水龙头",
    "Billing rules": "计费规则",
    "Congratulations tips": "恭喜您获得本次竞猜奖励， 奖励为500 ARES！",
    "Sorry, your test coins are insufficient to participate!": " 对不起， 您的测试币不足，无法参与！",
    "You have already participated in this prediction, you cannot participate repeatedly!": "您已经参与了本次竞猜， 不能反复参与！",
    "site desc": "Ares是一个链上验证的预言机协议，为波卡及DeFi生态系统提供安全和可靠的数据服务。",
    "successfully participated tips": "您已成功参与下面预测， 祝您好运！",
    "Receive test coins tips": "每人每天限领一次，一次可领取50个 ARES。由于测试币的发布仅用于测试网上的操作体验，因此 测试币没有实际的通证价值。",
    "you can get it through the tap below": "参与价格预测，首先你要有足够的测试币。如果你余额不足，可以通过下面的水龙头领取测试币",
    "closed one hour before tips": "为了保证竞猜的公平有效， 每期价格竞猜会在结束时间前1 个小时， 关闭预测窗口 。 请及时参与！",
    "Ares Protocol's mainnet alert tool keeps you informed of cryptocurrency price increases and decreases in real time.": "Ares Protocol的主网价格监测工具，让您实时了解加密货币价格的上涨和下跌。",
    "To ensure fairness, the deadline is 1 hour in advance": "为保证公平，提前1小时截止竞猜",
}


export default zhCommon;
