

const zhPopular = {
    "Popular": "热门栏目",
    "Having Countdown": "减半倒计时",
    "Reduction Countdown": "减半倒计时",
    "days": "天",
    "hours": "时",
    "minutes": "钟",
    "Estimated time to reduce": "预计减半时间",
    "Current block reward": "当前区块奖励",
    "Block reward after reduction": "减半后区块奖励",
    "Remaining blocks until reduction": "距离减半剩余区块",
    "Block generation time": "出块时间",
    "Blocks left to mine": "剩余可挖",
    "pre Share in": "在",
    "suf Share in": "上分享",
    "Scan QR": "扫描QR",
    "Join the community": "加入社区",
    "Share": "分享",
    "Cancel": "取消",
}

export default zhPopular;